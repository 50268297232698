import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import FamilyList from "../../components/FamilyList";
import DrawerMenu from "../../components/DrawerMenu";
import CustomAppBar from "../../components/AppBar";
import { jweDecryption, getJwkKeystore } from '../../security/crypt';
import requestWithAuth from "../../requests/request-with-auth";
import {useHistory} from "react-router-dom";
import AccessTokenStorage from "../../security/AccessTokenStorage";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        marginTop: 100,
        marginRight: 16,
        marginLeft: 16,
        width: '100%',
    },
}));

export default function FamilyListPage() {
    const classes = useStyles();
    const theme = useTheme();
    const [selectedIndex, setSelectedIndex] = React.useState('menu_families');
    const [open, setOpen] = React.useState(false);
    const [families, setFamilies] = React.useState([]);
    let history = useHistory();

    const parseFamilies= async (response)=>{
        try {
            let key = await getJwkKeystore();
            if (response !== '') {
                response = await jweDecryption(response, key);
                setFamilies(response);
            }
        } catch (e) {
            console.error(e);
            AccessTokenStorage.clear();
            history.push('/login');
        }
    }
    
    React.useEffect(() => {
        const requestURL = '/families';
        try {
            requestWithAuth(requestURL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }, history).then(parseFamilies);
        } catch (err) {
            console.error(err);
        }
    }, []);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenuItemSelected = (event, index) => {
        setSelectedIndex(index);
        handleDrawerClose()
    };

    return (
        <React.Fragment key='left'>
            <div className={classes.root}>
                <CssBaseline />
                <CustomAppBar handleDrawerOpen={handleDrawerOpen} title='Familles de produit'/>
                <DrawerMenu open={open} 
                            handleMenuItemSelected={handleMenuItemSelected} 
                            selectedIndex={selectedIndex}
                            handleDrawerClose={handleDrawerClose}
                />
                <main
                    className={classes.content}
                >
                    <FamilyList families={families} />
                </main>
            </div>
        </React.Fragment>
    );
}
