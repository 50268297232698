import React, {Component} from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import colors from "../../../Colors";
import Button from "@material-ui/core/Button";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import plus from "../../../images/plus.svg"
import minus from "../../../images/minus.svg"
import InputBase from '@material-ui/core/InputBase';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    cardContent: {
        padding: 8,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    card: {
        width: '100%',
        borderRadius : 8,
        marginTop: 16,
    },
    productPrice: {
        width: '100%',
        marginTop: 8,
    },
    productReference: {
        width: '100%',
        marginTop: 8,
    },
    productName: {
        width: '100%',
        height: 30,
    },
    media : {
        height: 40,
        display:'block',
        margin: 'auto',
    },
    button: {
        width: '100%',
        height: 48,
        borderRadius: 8,
        marginRight: 0,
        position: 'relative',
    },
    filter: {
        width: '100%',
    },
    hidden: {
        display: 'none',
    },
    paper: {
        backgroundColor: colors.indigo3,
        borderColor: colors.indigo,
        borderRadius : 4,
        height: 40,
        width: 160,
        margin: 'auto',
    }
}));

const InventoryTransfer = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const [values, setValues] = React.useState({
        sourceStock: '',
        destStock: '',
    });

    const [openSnack, setOpenSnack] = React.useState(false);
    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };
    const [product, setProduct] = React.useState({});
    const [selectedProducts, setSelectedProducts] = React.useState([]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value })
    };

    const handleChangeAutocomplete = (event, newValue) => {
        setProduct(newValue)
    };

    const handleChangeQuantity = (item) => (event) => {
        if(getRealAvailabilityByStock(selectedProducts.filter(p=> p.id === item)[0], values.sourceStock) >= event.target.value) {
            selectedProducts.filter(p => p.id === item)[0].quantity = event.target.value;
            setSelectedProducts([...selectedProducts]);
        }
    };

    const handleOnClick = ()=>{
        if(selectedProducts.filter(p=> p.id === product.id).length === 0){
            setSelectedProducts([...selectedProducts, {...product, quantity: 0}])
            setProduct({})
        }
        else{
            setOpenSnack(true)
        }
    };

    const handleOnClickPlus = (item)=>{
        if(getRealAvailabilityByStock(selectedProducts.filter(p=> p.id === item)[0], values.sourceStock) >= selectedProducts.filter(p=> p.id === item)[0].quantity+1){
            selectedProducts.filter(p=> p.id === item)[0].quantity++;
            setSelectedProducts([...selectedProducts]);
        }
    };

    const handleOnClickMinus = (item)=>{
        if(selectedProducts.filter(p=> p.id === item)[0].quantity>=1){
            selectedProducts.filter(p=> p.id === item)[0].quantity--;
            setSelectedProducts([...selectedProducts]);
        }
    };

    var {products, stocks} = props

    const getAvailabilityByStock = (product, stockId) => {
        var stock = 0;
        product.availability.map((productStock)=>{
            if(stockId === '' || stockId === 'Tous les stocks'){
                stock = stock + productStock.quantity;
            }
            else{
                if(productStock.repoName === stockId){
                    stock = productStock.quantity;
                }
            }
        });
        return stock;
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        ignoreAccents: true,
        ignoreCase: true,
        stringify: option => option.reference+' '+option.name,
    });

    const getRealAvailabilityByStock = (product, stockId) => {
        var stock = 0;
        product.availability.map((productStock)=>{
            if(stockId === '' || stockId === 'Tous les stocks'){
                stock = stock + productStock.quantity - productStock.reserved;
            }
            else{
                if(productStock.repoName === stockId){
                    stock = productStock.quantity - productStock.reserved;
                }
            }
        });
        return stock;
    };

    return (
        <Grid container spacing={0} direction="row"
              justify="flex-start"
              alignItems="flex-start">
            <Grid item xs={12}>
                <Typography align='center' variant='body1'>Afin de transférer des produits d'un stock à un autre, merci de saisir les informations suivantes</Typography>
            </Grid>
            <Grid item xs={12} style={{marginTop: 20}}>
                <Typography variant='body1'>Merci de spécifier le stock source et le stock destination</Typography>
            </Grid>
            <Grid item xs={12} style={{marginTop: 8}}>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <TextField select className={classes.filter}
                                   id="sourceStock"
                                   label="Stock source"
                                   variant="outlined"
                                   value={values.sourceStock}
                                   onChange={handleChange('sourceStock')}
                        >
                            {stocks.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField select className={classes.filter}
                                   id="destStock"
                                   label="Stock destination"
                                   variant="outlined"
                                   value={values.destStock}
                                   onChange={handleChange('destStock')}
                        >
                            {stocks.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{marginTop: 20}}>
                <Typography variant='body1'>Produits à transférer</Typography>
            </Grid>
            <Grid item xs={12} style={{marginTop: 8}}>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <Autocomplete
                            value={product}
                            className={classes.filter}
                            onChange={handleChangeAutocomplete}
                            options={products}
                            filterOptions={filterOptions}
                            getOptionLabel={(option) => option!==null && option.hasOwnProperty('name') ? option.name: ""}
                            id="product"
                            variant="outlined"
                            renderOption={(option, { selected }) => (
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <div style={{height: 40, width:70}}>
                                            <img className={classes.media}
                                                 src={option.image}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs>
                                        <div>
                                            <Typography variant='body2' color='primary'>{option.reference}</Typography>
                                            <Typography variant='body1'>{option.name}</Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                            )}
                            renderInput={(params) => <TextField {...params} label="Produit" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="outlined" color='primary' className={classes.button} onClick={handleOnClick}>AJOUTER LE PRODUIT</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                        <Grid container spacing={2} direction="row"
                              justify="flex-start"
                              alignItems="center" style={{background: colors.indigo3}}>
                            <Grid item xs={4}>
                                <Typography variant='h4'>Produit</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container direction="row">
                                    <Grid item xs={12}>
                                        <Typography variant='h4' display='block' align='center'>Stock Actuel</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant='h4' display='block' align='center'>{values.sourceStock}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant='h4' display='block' align='center'>{values.destStock}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} style={{margin:'auto'}}>
                                <Typography variant='h4' display='block' align='center'>Quantité à transférer</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container direction="row">
                                    <Grid item xs={12}>
                                        <Typography variant='h4' display='block' align='center'>Nouveau Stock</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant='h4' display='block' align='center'>{values.sourceStock}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant='h4' display='block' align='center'>{values.destStock}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container style={{maxHeight: `calc(100vh - 468px)`, minHeight: 100, overflow: 'scroll', marginTop:8}}>
                            {
                                selectedProducts.map((item, index) => (
                                    <Grid container spacing={2} direction="row"
                                          justify="flex-start"
                                          alignItems="flex-start" style={{height: 80}} key={item.id}>
                                        <Grid item xs={4} style={{margin:'auto'}}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <div style={{height: 40, width:70}}>
                                                        <img className={classes.media}
                                                             src={item.image}/>
                                                    </div>
                                                </Grid>
                                                <Grid item xs>
                                                    <div>
                                                        <Typography variant='body2' color='primary'>{item.reference}</Typography>
                                                        <Typography variant='body1'>{item.name}</Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3} style={{margin:'auto'}}>
                                            <Grid container direction="row" alignItems="center">
                                                <Grid item xs={6}>
                                                    <Typography variant='h4' color="primary" display='block' align='center'>{getRealAvailabilityByStock(item, values.sourceStock)}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant='h4' color="primary" display='block' align='center'>{getRealAvailabilityByStock(item, values.destStock)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} style={{margin:'auto'}}>
                                            <Paper variant="outlined" className={classes.paper}>
                                                <Grid container>
                                                    <Grid item xs={3}>
                                                        <IconButton component="span" style={{height: 40, width:'40',margin:'auto'}} onClick={()=>handleOnClickMinus(item.id)}>
                                                            <img src={minus} style={{height: 14, width:14,margin:'auto'}}/>
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item xs={6} style={{display: 'flex', background:'white', marginTop: 1, marginBottom: 2}}>
                                                        <InputBase
                                                            color="primary"
                                                            className={classes.margin}
                                                            style={{width: '100%', margin:'auto'}}
                                                            value={item.quantity}
                                                            inputProps={{ style: {textAlign: 'center'}}}
                                                            onChange={handleChangeQuantity(item.id)}
                                                            type="number"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <IconButton component="span" style={{height: 40, width:40, margin:'auto'}} onClick={()=>handleOnClickPlus(item.id)}>
                                                            <img src={plus} style={{height: 14, width:14,margin:'auto'}}/>
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                        <Grid item xs={3} style={{margin:'auto'}}>
                                            <Grid container direction="row" alignItems="center">
                                                <Grid item xs={6}>
                                                    <Typography variant='h4' display='block' align='center' style={{color:colors.green}}>{getRealAvailabilityByStock(item, values.sourceStock)-Number(item.quantity)}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant='h4' display='block' align='center' style={{color:colors.green}}>{getRealAvailabilityByStock(item, values.destStock)+Number(item.quantity)}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid container justify="flex-end" style={{marginTop: 24}}>
                <Grid item xs={2}>
                    <Button variant='contained' color='primary' className={classes.button}>Soumettre</Button>
                </Grid>
            </Grid>
            <Snackbar open={openSnack} autoHideDuration={2000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="info">
                    Vous avez déjà ajouté ce produit à la liste
                </Alert>
            </Snackbar>
        </Grid>
    )
}
export default InventoryTransfer;