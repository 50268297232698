import React, {Component} from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
    cardContent: {
        height: 30,
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    card: {
        borderRadius : 8,
    },
    familyName: {
        width: '100%'
    },
    media : {
        height: 130,
        position: 'relative',
    },
    more: {
        position: 'absolute',
        right: '0',
        top: '0'
    },
}));

const FamilyList = (props) => {
    const classes = useStyles();
    const theme = useTheme();
        return (
            <Grid container spacing={2}>
                {
                    props.families.filter(f=> f.parentId === null).map((item, index) => (
                        <Grid item xs={3} key={item.id}>
                            <Card className={classes.card}>
                                <CardMedia className={classes.media}
                                           image={item.image}>
                                    <IconButton aria-label="settings" className={classes.more}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </CardMedia>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant='body2' align='center' color='textPrimary' className={classes.familyName}>{item.name}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))
                }
            </Grid>
        )
}
export default FamilyList;
