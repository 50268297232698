import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme) => ({
    appBar: {
        height: 80,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor : '#FAFAFA',
    },
    toolbar: {
        height: 56
    },
    card: {
        marginLeft : 12,
        marginTop : 12,
        marginRight : 12,
        alignItems: 'center',
        display: 'flex',
        height: 56,
        borderRadius : 8,
    },
}));

export default function CustomAppBar(props) {
    const classes = useStyles();
    const theme = useTheme();
    return (
                <AppBar elevation={0}
                        position="fixed"
                        className={classes.appBar}
                >
                    <Card className={classes.card}>
                        <Toolbar className={classes.toolbar}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={props.handleDrawerOpen}
                                edge="start"
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="h6" color='textPrimary' noWrap>
                                {props.title}
                            </Typography>
                        </Toolbar>
                    </Card>
                </AppBar>

    );
}
