import { request } from './request';
import AccessTokenStorage from '../security/AccessTokenStorage';

const requestWithAuth = (url, options, history) => {
	const optionsWithAuth = {
		...options,
		headers: {
			...options.headers,
			Authorization: AccessTokenStorage.getToken()
		}
	};
	return request(url, optionsWithAuth).catch(e => {
		console.info(e.response.status)
		if (e.response && e.response.status === 401) {
			AccessTokenStorage.clear();
			history.push('/login');
		} else throw e;
	});
};

export default requestWithAuth;
