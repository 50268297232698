import React, {Component} from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import colors from "../../Colors";

const useStyles = makeStyles((theme) => ({
    cardContent: {
        padding: 8,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    card: {
        width: '100%',
        borderRadius : 8,
        marginTop: 16,
    },
    productPrice: {
        width: '100%',
        marginTop: 8,
    },
    productReference: {
        width: '100%',
        marginTop: 8,
    },
    productName: {
        width: '100%',
        height: 30,
    },
    media : {
        height: 40,
        display:'block',
        margin: 'auto',
    },
    button: {
        width: '100%',
        height: 48,
        borderRadius: 0,
    },
    filter: {
        width: '100%',
    },
    hidden: {
        display: 'none',
    },
}));

const Inventory = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const [values, setValues] = React.useState({
        brand: 'Toutes les marques',
        stock: 'Tous les stocks',
        order: 'Référence',
    });
    const [family, setFamily] = React.useState(0);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };
    const handleChangeFamily = (event) => {
        setFamily(event.target.value);
    };

    var {families, brands, stocks} = props
    families = [{id:0, name:'Toutes les familles', parentId:null}, ...families];
    brands = [{id:0, name:'Toutes les marques'}, ...brands];
    stocks = [{id:0, name:'Tous les stocks'}, ...stocks];

    const getAvailabilityByStock = (product, stockId) => {
        var stock = 0;
        console.info(product)
        console.info(stockId)
        product.availability.map((productStock)=>{
            if(stockId === '' || stockId === 'Tous les stocks'){
                stock = stock + productStock.quantity;
            }
            else{
                if(productStock.repoName === stockId){
                    console.info("stock name equals stock id");
                    console.info(productStock.quantity);
                    stock = productStock.quantity;
                }
            }
        });
        return stock;
    };

    const getRealAvailabilityByStock = (product, stockId) => {
        var stock = 0;
        console.info(product)
        console.info(stockId)
        product.availability.map((productStock)=>{
            if(stockId === '' || stockId === 'Tous les stocks'){
                stock = stock + productStock.quantity - productStock.reserved;
            }
            else{
                if(productStock.repoName === stockId){
                    console.info("stock name equals stock id");
                    console.info(productStock.quantity);
                    stock = productStock.quantity - productStock.reserved;
                }
            }
        });
        return stock;
    };
    
    return (
        <Grid container spacing={0} direction="row"
              justify="flex-start"
              alignItems="flex-start">
            <Grid item xs={12}>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <TextField select className={classes.filter}
                                   id="stock"
                                   label="Stock"
                                   variant="outlined"
                                   value={values.stock}
                                   onChange={handleChange('stock')}
                        >
                            {stocks.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField select className={classes.filter}
                                   id="family"
                                   label="Famille"
                                   variant="outlined"
                                   value={family}
                                   onChange={handleChangeFamily}
                        >
                            {families.filter(f=>f.parentId === null).map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField select className={classes.filter}
                                   id="brand"
                                   label="Marque"
                                   variant="outlined"
                                   value={values.brand}
                                   onChange={handleChange('brand')}
                        >
                            {brands.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField select className={classes.filter}
                                   id="order"
                                   label="Trier par"
                                   variant="outlined"
                                   value={values.order}
                                   onChange={handleChange('order')}
                        >
                            {props.sorts.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                <Grid container spacing={2} direction="row"
                      justify="flex-start"
                      alignItems="flex-start" style={{background: colors.indigo3}}>
                    <Grid item xs={4}>
                        <Typography variant='h4'>Produit</Typography>                       
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant='h4'>Marque</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant='h4'>Famille</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant='h4' display='block' align='center'>Prix d'achat</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant='h4' display='block' align='center'>Quantité</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant='h4' display='block' align='center'>Disponible</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={0} direction="row" justify="flex-start" alignItems="baseline" style={{maxHeight: `calc(100vh - 200px)`, overflow: 'scroll', marginTop:8}}>
                    {
                        props.products.filter(p => ((family === 0 || p.family.id === family
                            || (p.family.parentId !== null && (p.family.parentId === family || (families.filter(f=>f.id === p.family.parentId)[0].parentId !== null && families.filter(f=>f.id === p.family.parentId)[0].parentId === family))))
                            && (values.brand === '' || values.brand === 'Toutes les marques' || p.brand.name === values.brand))
                        ).sort((a,b) => {
                            if(values.order === 'Nom'){
                                return a.name.localeCompare(b.name);
                            }
                            else if(values.order === 'Marque'){
                                return a.brand.name.localeCompare(b.brand.name);
                            }
                            else if(values.order === 'Famille'){
                                return a.family.name.localeCompare(b.family.name);
                            }
                            else if(values.order === 'Prix'){
                                return a.avgPurchasePrice > b.avgPurchasePrice ? 1 : -1;
                            }
                            else if(values.order === 'Disponibilité'){
                                return getRealAvailabilityByStock(a, values.stock) > getRealAvailabilityByStock(b, values.stock) ? 1 : -1;
                            }
                            else {
                                return a.reference.localeCompare(b.reference);
                            }
                        }).map((item, index) => (
                            <Grid container spacing={2} direction="row"
                                  justify="flex-start"
                                  alignItems="flex-start" style={{height: 80}}>
                                <Grid item xs={4} style={{margin:'auto'}}>
                                    <Grid container spacing={2}>
                                    <Grid item>
                                        <div style={{height: 40, width:70}}>
                                            <img className={classes.media}
                                                 src={item.image}/>
                                        </div>
                                    </Grid>
                                    <Grid item xs>
                                        <div>
                                            <Typography variant='body2' color='primary'>{item.reference}</Typography>
                                            <Typography variant='body1'>{item.name}</Typography>
                                        </div>
                                    </Grid>
                                    </Grid>
                            </Grid>
                        <Grid item xs={2} style={{margin:'auto'}}>
                        <Typography variant='body1'>{item.brand.name}</Typography>
                        </Grid>
                        <Grid item xs={2} style={{margin:'auto'}}>
                        <Typography variant='body1'>{item.family.name}</Typography>
                        </Grid>
                        <Grid item xs={2} style={{margin:'auto'}}>
                        <Typography variant='body1' color='primary' display='block' align='center'>{item.avgPurchasePrice!==null ? item.avgPurchasePrice.toFixed(2) : 0 +' DHs'}</Typography>
                        </Grid>
                        <Grid item xs={1} style={{margin:'auto'}}>
                        <Typography variant='body1' color={getAvailabilityByStock(item, values.stock)>=10? 'textPrimary' : 'error'} display='block' align='center'>{getAvailabilityByStock(item, values.stock)}</Typography>
                        </Grid>
                        <Grid item xs={1} style={{margin:'auto'}} alignItems='center'>
                        <Typography variant='body1' color={getAvailabilityByStock(item, values.stock)>=10? 'textPrimary' : 'error'} display='block' align='center'>{getRealAvailabilityByStock(item, values.stock)}</Typography>
                        </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}
export default Inventory;
