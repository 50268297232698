import React from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from "@material-ui/core/Typography";
import grayfox from '../../images/grayfox.svg'
import SvgIcon from '@material-ui/core/SvgIcon';
import colors from '../../Colors'
import {ReactComponent as icDashboardGrey} from "../../images/grey/ic_dashboard.svg";
import {ReactComponent as icDashboardWhite} from "../../images/white/ic_dashboard.svg";
import {ReactComponent as icBrandsGrey} from "../../images/grey/ic_brands.svg";
import {ReactComponent as icBrandsWhite} from "../../images/white/ic_brands.svg";
import {ReactComponent as icFamiliesGrey} from "../../images/grey/ic_families.svg";
import {ReactComponent as icFamiliesWhite} from "../../images/white/ic_families.svg";
import {ReactComponent as icProductsGrey} from "../../images/grey/ic_products.svg";
import {ReactComponent as icProductsWhite} from "../../images/white/ic_products.svg";
import {ReactComponent as icClientsGrey} from "../../images/grey/ic_clients.svg";
import {ReactComponent as icClientsWhite} from "../../images/white/ic_clients.svg";
import {ReactComponent as icStorageGrey} from "../../images/grey/ic_storage.svg";
import {ReactComponent as icStorageWhite} from "../../images/white/ic_storage.svg";
import {ReactComponent as icAccountGrey} from "../../images/grey/ic_account.svg";
import {ReactComponent as icAccountWhite} from "../../images/white/ic_account.svg";

export const MENU = [
    {
        isGroup: false,
        groupTitle: '',
        items: [{
            label: 'Tableau de bord',
            id: 'menu_dashboard',
            icon: icDashboardGrey,
            iconSelected: icDashboardWhite,
            path : '/home',
        },
        ],
    },
    {
        isGroup: true,
        groupTitle: 'Catalogue',
        items: [{
            label: 'Marques',
            id: 'menu_brands',
            icon: icBrandsGrey,
            iconSelected: icBrandsWhite,
            path : '/brands',
        },
            {
                label: 'Familles',
                id: 'menu_families',
                icon: icFamiliesGrey,
                iconSelected: icFamiliesWhite,
                path : '/families',
            },
            {
                label: 'Produits',
                id: 'menu_products',
                icon: icProductsGrey,
                iconSelected: icProductsWhite,
                path : '/products',
            },
        ],
    },
    {
        isGroup: true,
        groupTitle: 'Ventes',
        items: [{
            label: 'Paniers',
            id: 'menu_charts',
            icon: icProductsGrey,
            iconSelected: icProductsWhite,
            path : '/carts',
        },
            {
                label: 'Commandes',
                id: 'menu_orders',
                icon: icProductsGrey,
                iconSelected: icProductsWhite,
                path : '/orders',
            },
            {
                label: 'Clients',
                id: 'menu_clients',
                icon: icClientsGrey,
                iconSelected: icClientsWhite,
                path : '/clients',
            },
        ],
    },
    {
        isGroup: true,
        groupTitle: 'Gestion de stock',
        items: [{
            label: 'Stocks',
            id: 'menu_storage',
            icon: icStorageGrey,
            iconSelected: icStorageWhite,
            path : '/stocks',
        },
            {
                label: 'Alimenter le stock',
                id: 'menu_fill_storage',
                icon: icStorageGrey,
                iconSelected: icStorageWhite,
                path : '/fill-stock',
            },
            {
                label: 'Transfert de stock',
                id: 'menu_transfer_storage',
                icon: icStorageGrey,
                iconSelected: icStorageWhite,
                path : '/transfer-stock',
            },
            {
                label: 'Fournisseurs',
                id: 'menu_suppliers',
                icon: icClientsGrey,
                iconSelected: icClientsWhite,
                path : '/suppliers',
            },
        ],
    },
    {
        isGroup: true,
        groupTitle: 'Administration',
        items: [{
            label: 'Utilisateurs',
            id: 'menu_accounts',
            icon: icAccountGrey,
            iconSelected: icAccountWhite,
            path : '/accounts',
        },
        ],
    },
];

const useStyles = makeStyles((theme) => ({
    list: {
        width: 260,
        paddingRight: 8,
        paddingLeft: 8,
    },
    logo: {
        marginRight: 16,
        marginLeft: 16,
    },
    listItem: {
        color: colors.dark_indigo,
        '&.Mui-selected': {
            background: "linear-gradient(90deg, rgba(105,121,248,1) 0%, rgba(165,175,251,1) 100%)",
            color: 'white',
            borderRadius: 8,
        },
    },
    listItemIcon:{
        alignItems: 'center',
    }
}));

export default function DrawerMenu(props) {
    const classes = useStyles();
    const theme = useTheme();

    const list = () => (
        <div
            className={clsx(classes.list)}
            role="presentation"
        >
                {MENU.map((menuGroup, index) => (
                    <div key={menuGroup.groupTitle}>
                        {menuGroup.isGroup === true && 
                            <Typography variant="subtitle1" color='textSecondary' style={{marginLeft: 12, marginTop: 8}}>{menuGroup.groupTitle}</Typography>}
                        <List>
                            {menuGroup.items.map((item, index) => (
                                <ListItem classes={{ root: classes.listItem }} button key={item.id} selected={props.selectedIndex === item.id}
                                          onClick={(event) => props.handleMenuItemSelected(event, item.id)}
                                          component="a"
                                          href={item.path}>
                                    <ListItemIcon className={classes.listItemIcon}><SvgIcon component={props.selectedIndex === item.id ? item.iconSelected : item.icon}/></ListItemIcon>
                                    <ListItemText primary={item.label} />
                                </ListItem>
                                ))}
                        </List>
                        </div>
                    )
                )}
        </div>
    );
    
    return (
        <Drawer anchor='left' open={props.open} onClose={props.handleDrawerClose}>
            <img src={grayfox} className={classes.logo}/>
            {list()}
        </Drawer>
    );
}
