import React, {Component} from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import grayfox from "../../images/grayfox.svg";
import Typography from "@material-ui/core/Typography";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
    content: {
        width: '40%',
        margin: 'auto',
    },
    username: {
        width: '100%',
        marginTop: 24,
    },
    password: {
        width: '100%',
        marginTop: 8,
    },
    button: {
        width: '100%',
        height: 48,
        marginTop: 16,
    },
    logo: {
        width: '50%',
        margin: 'auto',
    },
}));

const Login = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const [values, setValues] = React.useState({
        username: '',
        password: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const submitForm = () => {
        props.signIn(values)
    }

    return (
        <form>
        <Grid container spacing={2} className={classes.content}>
            <Grid item xs={12} style={{display:'flex', alignItems: 'center'}}>
                <img src={grayfox} className={classes.logo}/>
            </Grid>
            <Grid item xs={12} style={{display:'flex', alignItems: 'center'}}>
                <Typography variant="h3" align='center' style={{margin: 'auto'}}>Bienvenue sur GrayFox</Typography>
            </Grid>
            <Grid item xs={12} style={{display:'flex', alignItems: 'center'}}>
                <Typography variant="body1" align='center' style={{margin: 'auto'}}>Utilisez votre nom d'utilisateur et votre mot de passe pour vous connecter</Typography>
            </Grid>
            <Grid item xs={8} style={{margin: 'auto'}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField className={classes.username}  autoComplete='false'
                                   id="username"
                                   label="Nom d'utilisateur"
                                   variant="outlined"
                                   value={values.username}
                                   onChange={handleChange('username')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField className={classes.password} autoComplete='false'
                                   id="password"
                                   label="Mot de passe"
                                   variant="outlined"
                                   type={values.showPassword ? 'text' : 'password'}
                                   value={values.password}
                                   onChange={handleChange('password')}
                                   InputProps={{
                                       endAdornment:
                                           <InputAdornment position="end">
                                               <IconButton
                                                   aria-label="toggle password visibility"
                                                   onClick={handleClickShowPassword}
                                                   onMouseDown={handleMouseDownPassword}
                                                   edge="end"
                                               >{values.showPassword ? <Visibility/> : <VisibilityOff/>}
                                               </IconButton>
                                           </InputAdornment>
                                   }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button className={classes.button} color="primary" variant="text" onClick={submitForm}>S'ENREGISTRER</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button className={classes.button} color="primary" variant="contained" onClick={submitForm}>SE CONNECTER</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </form>
    )
}
export default Login;