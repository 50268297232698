import React, {Component} from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
    cardContent: {
        padding: 16,
        "&:last-child": {
            paddingBottom: 16
        }
    },
    cardActions: {
        marginTop:8,
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    card: {
        borderRadius : 8,
    },
    productPrice: {
        width: '100%',
        marginTop: 8,
    },
    productReference: {
        width: '100%',
        marginTop: 8,
    },
    productName: {
        width: '100%',
        height: 30,
    },
    media : {
        height: 180,
        display:'block',
        margin: 'auto',
    },
    button: {
        width: '100%',
        height: 48,
        borderRadius: 0,
    },
    filter: {
        width: '100%',
    },
    hidden: {
        display: 'none',
    },
}));

const ProductList = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const [values, setValues] = React.useState({
        brand: '',
        order: '',
    });
    
    const handleChange = (prop) => (event) => {
        console.info("event : "+event.target.value)
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleChangeFamily = (event) => {
        setFamily(event.target.value )
    };

    const [family, setFamily] = React.useState(0);

    var {families, brands} = props
    families = [{id:0, name:'Toutes les familles', parentId: null}, ...families];
    brands = [{id:0, name:'Toutes les marques'}, ...brands];
    
    return (
        <Grid container spacing={0} direction="row"
              justify="flex-start"
              alignItems="flex-start">
            <Grid item xs={12}>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <TextField select className={classes.filter}
                                   id="family"
                                   label="Famille"
                                   variant="outlined"
                                   value={family}
                                   onChange={handleChangeFamily}
                        >
                            {families.filter(f=> f.parentId === null).map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField select className={classes.filter}
                                   id="brand"
                                   label="Marque"
                                   variant="outlined"
                                   value={values.brand}
                                   onChange={handleChange('brand')}
                        >
                            {brands.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField select className={classes.filter}
                                   id="order"
                                   label="Trier par"
                                   variant="outlined"
                                   value={values.order}
                                   onChange={handleChange('order')}
                        >
                            {props.sorts.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
        <Grid container spacing={2} direction="row"
              justify="flex-start"
              alignItems="flex-start" style={{height: `calc(100vh - 148px)`, marginTop:8, overflow: 'scroll',}}>
            {
                props.products.filter(p => ((family === 0 || p.family.id === family
                    || (p.family.parentId !== null && (p.family.parentId === family || (families.filter(f=>f.id === p.family.parentId)[0].parentId !== null && families.filter(f=>f.id === p.family.parentId)[0].parentId === family))))
                && (values.brand === '' || values.brand === 'Toutes les marques' || p.brand.name === values.brand))
                ).sort((a,b) => {
                    if(values.order === 'Nom'){
                        return a.name.localeCompare(b.name);
                    }
                    else if(values.order === 'Marque'){
                        return a.brand.name.localeCompare(b.brand.name);
                    }
                    else if(values.order === 'Famille'){
                        return a.family.name.localeCompare(b.family.name);
                    }
                    else if(values.order === 'Prix'){
                        return a.priceTTC > b.priceTTC ? 1 : -1;
                    }
                    else {
                        return a.reference.localeCompare(b.reference);
                    }
                }).map((item, index) => (
                    <Grid item xs={2} key={item.id}>
                        <Card className={classes.card}>
                            <CardContent className={classes.cardContent}>
                                <img className={classes.media}
                                           src={item.image}/>
                                <Typography variant='h3' align='right' color='primary' className={classes.productPrice}>{item.priceTTC.toFixed(2)+' DHs'}</Typography>
                                <Typography variant='caption' align='left' color='textPrimary' className={classes.productReference}>{item.reference}</Typography>
                                <Typography variant='body2' align='left' color='textPrimary' className={classes.productName}>{item.name}</Typography>
                            </CardContent>
                            <CardActions disableSpacing className={classes.cardActions}>
                                <Grid container spacing={0}>
                                    <Grid item xs={6}>
                                        <Button variant='contained' color='secondary' className={classes.button}
                                                startIcon={<EditIcon />}>Éditer</Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button variant='contained' color='primary' className={classes.button}
                                                startIcon={<DeleteIcon />}>Supprimer</Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>
                ))
            }
        </Grid>

            </Grid>
        </Grid>
    )
}
export default ProductList;
