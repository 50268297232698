import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import Inventory from "../../components/Inventory";
import DrawerMenu from "../../components/DrawerMenu";
import CustomAppBar from "../../components/AppBar";
import { jweDecryption, getJwkKeystore } from '../../security/crypt';
import requestWithAuth from "../../requests/request-with-auth";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        marginTop: 84,
        marginRight: 16,
        marginLeft: 16,
        width: '100%',
        ...theme.mixins.toolbar
    },
}));

export default function InventoryPage() {
    const classes = useStyles();
    const theme = useTheme();
    const [selectedIndex, setSelectedIndex] = React.useState('menu_stocks');
    const [open, setOpen] = React.useState(false);
    const [products, setProducts] = React.useState([]);
    const [brands, setBrands] = React.useState([]);
    const [families, setFamilies] = React.useState([]);
    const [stocks, setStocks] = React.useState([]);
    const MSG_DECRYPT_ERROR = 'KEY NOT VALID SESSION TIME OUT';
    let history = useHistory();

    const parseProducts= async (response)=>{
        try {
            let key = await getJwkKeystore();
            if (response !== '') {
                response = await jweDecryption(response, key);
                setProducts(response.content);
            }
        } catch (e) {
            console.error(e);
        }
    }
    const parseBrands= async (response)=>{
        try {
            let key = await getJwkKeystore();
            if (response !== '') {
                response = await jweDecryption(response, key);
                setBrands(response);
            }
        } catch (e) {
            console.error(e);
            throw Error(MSG_DECRYPT_ERROR);
        }
    }
    const parseFamilies= async (response)=>{
        try {
            let key = await getJwkKeystore();
            if (response !== '') {
                response = await jweDecryption(response, key);
                setFamilies(response);
            }
        } catch (e) {
            console.error(e);
            throw Error(MSG_DECRYPT_ERROR);
        }
    }
    const parseStocks= async (response)=>{
        try {
            let key = await getJwkKeystore();
            if (response !== '') {
                response = await jweDecryption(response, key);
                setStocks(response);
            }
        } catch (e) {
            console.error(e);
            throw Error(MSG_DECRYPT_ERROR);
        }
    }
    const sorts = [
        'Référence',
        'Nom',
        'Marque',
        'Famille',
        'Prix',
        'Disponibilité'
    ];

    React.useEffect(() => {
        try {
            requestWithAuth('/products?page=0&size=10000', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }}, history
            ).then(parseProducts);
            requestWithAuth('/brands', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }, history).then(parseBrands);
            requestWithAuth('/families', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }, history).then(parseFamilies);
            requestWithAuth('/repos', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }, history).then(parseStocks);
        } catch (err) {
            console.error(err);
        }
    }, []);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenuItemSelected = (event, index) => {
        setSelectedIndex(index);
        handleDrawerClose()
    };

    return (
        <React.Fragment key='left'>
            <div className={classes.root}>
                <CssBaseline />
                <CustomAppBar handleDrawerOpen={handleDrawerOpen} title='Stocks'/>
                <DrawerMenu open={open}
                            handleMenuItemSelected={handleMenuItemSelected}
                            selectedIndex={selectedIndex}
                            handleDrawerClose={handleDrawerClose}
                />
                <main
                    className={classes.content}
                >
                    <Inventory products={products} families={families} brands={brands} stocks={stocks} sorts={sorts}/>
                </main>
            </div>
        </React.Fragment>
    );
}
