import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import Login from "../../components/Login";
import { aes256gcm, getTransKey } from '../../security/crypt';
import tokenStorage from '../../security/AccessTokenStorage'
import { requestLogin } from '../../requests/request';
import AccessTokenStorage from "../../security/AccessTokenStorage";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function LoginPage() {
    const classes = useStyles();
    const theme = useTheme();
    let history = useHistory();
    const transKey = getTransKey();

    const parseLoginRequest = (response) => {
        if(response && response.token)
        {
            const aesCipher = aes256gcm(Buffer.from(transKey.clair, 'hex'));
             const key = aesCipher.decrypt(
                 Buffer.from(response.key.slice(0, 32), 'hex'),
                 '0000000000000000',
                 Buffer.from(response.key.slice(32, 64), 'hex'),
             );
             tokenStorage.setJweKey(key);
            tokenStorage.setToken(response.token);
            const user = response.user;
            tokenStorage.setRole(user.role);
            history.push('/dashboard');
        }
    };
    
    const signIn = (credentials) => {
        const requestURL = '/login';
        try {
            tokenStorage.clear();
            const form = new FormData();
            form.append('username', credentials.username);
            form.append('password', credentials.password);
            form.append('transKey', transKey.encrypted);
            requestLogin(requestURL, {
                body: form,
            }).then(parseLoginRequest);
        } catch (err) {

        }
    }

    return (
            <div className={classes.root}>
                <CssBaseline />
                <Login signIn={signIn}/>
            </div>
    );
}