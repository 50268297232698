const colors = {
    purple:'#BE52F2',
    purple2:'#DBA5F5',
    purple3:'#EEDFF2',
    indigo:'#6979F8',
    indigo2:'#A5AFFB',
    indigo3:'#E5E7FA',
    yellow:'#FFCF5C',
    yellow2:'#FFE29D',
    yellow3:'#FFF8E7',
    orange:'#FFA26B',
    orange2:'#FFC7A6',
    orange3:'#FFE8DA',
    blue:'#0084F4',
    blue2:'#66B5F8',
    blue3:'#D5E9FA',
    green:'#00C48C',
    green2:'#7DDFC3',
    green3:'#D5F2EA',
    red:'#FF647C',
    red2:'#FDAFBB',
    red3:'#FBE4E8',
    dark_grey:'#1A051D',
    light_black:'#2c2c2c',
    lighter_black:'#393434',
    dark_indigo:'#3F3356',
    grey:'#D0C9D6',
    grey2:'#ECE9F1',
    grey3:'#F7F5F9',
    grey4:'#ecebed',
    beige:'#ededed',
    white:'#ffffff',
    gradient_indigo_light_start:'#C1C8FF',
    gradient_indigo_light_end:'#DDEBFE',
    purple_start_color:'#8461C9',
    purple_end_color:'#BD7AE3',
    gradient_purple_light_start:'#CDBAFA',
    gradient_purple_light_end:'#CDDDF4',
    gradient_indigo_chart_start:'#b7b1f7',
    gradient_orange_chart_start:'#ffc089',
}

export default colors;
