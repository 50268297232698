import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import BrandList from "../../components/BrandList";
import DrawerMenu from "../../components/DrawerMenu";
import CustomAppBar from "../../components/AppBar";
import {getJwkKeystore, jweDecryption} from "../../security/crypt";
import requestWithAuth from "../../requests/request-with-auth";
import {useHistory} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        marginTop: 100,
        marginRight: 16,
        marginLeft: 16,
        width: '100%',
    },
}));

export default function BrandListPage() {
    const classes = useStyles();
    const theme = useTheme();
    const [selectedIndex, setSelectedIndex] = React.useState('menu_brands');
    const [open, setOpen] = React.useState(false);
    const [brands, setBrands] = React.useState([]);
    const MSG_DECRYPT_ERROR = 'KEY NOT VALID SESSION TIME OUT';
    let history = useHistory();

    const parseBrands= async (response)=>{
        try {
            let key = await getJwkKeystore();
            if (response !== '') {
                response = await jweDecryption(response, key);
                setBrands(response);
            }
        } catch (e) {
            console.error(e);
            throw Error(MSG_DECRYPT_ERROR);
        }
    }

    React.useEffect(() => {
        const requestURL = '/brands';
        try {
            requestWithAuth(requestURL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }, history).then(parseBrands);
        } catch (err) {
            console.error(err);
        }
    }, []);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenuItemSelected = (event, index) => {
        setSelectedIndex(index);
        handleDrawerClose()
    };

    return (
        <React.Fragment key='left'>
            <div className={classes.root}>
                <CssBaseline />
                <CustomAppBar handleDrawerOpen={handleDrawerOpen} title='Marques'/>
                <DrawerMenu open={open}
                            handleMenuItemSelected={handleMenuItemSelected}
                            selectedIndex={selectedIndex}
                            handleDrawerClose={handleDrawerClose}
                />
                <main
                    className={classes.content}
                >
                    <BrandList brands={brands} />
                </main>
            </div>
        </React.Fragment>
    );
}