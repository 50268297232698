const crypto = require('crypto');

export const TOKEN_KEY = 'TOKEN';
export const ROLE_KEY = 'ROLE';
export const JWE_KEY = 'SESSION_KEY';

const SECRET_KEY = 'ae4308a8210833de98f5de2cefe17c37';
const aes256gcm = keyClair => {
  const ALGO = 'aes-128-gcm';
  const decrypt = (enc, iv, authTag) => {
    const decipher = crypto.createDecipheriv(
      ALGO,
      keyClair,
      Buffer.from(iv, 'hex'),
    );
    decipher.setAuthTag(authTag);
    let str = decipher.update(enc, 'hex', 'hex');
    str += decipher.final('hex');
    return str;
  };
  const encrypt = (enc, iv) => {
    const cipher = crypto.createCipheriv(
      ALGO,
      keyClair,
      Buffer.from(iv, 'hex'),
    );
    let str = cipher.update(enc, 'hex', 'hex');
    str += cipher.final('hex');
    str += cipher.getAuthTag().toString('hex');
    return str;
  };
  return {
    decrypt, encrypt
  };
};

/** Add encodage to Storage prototype */
const b64e = (str) =>
  btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
    String.fromCharCode(`0x${p1}`)
  ));

const b64d = (str) => {
  let strDecoded;
  try {
    strDecoded = decodeURIComponent(Array.prototype.map.call(atob(str), c =>
      `%${c.charCodeAt(0).toString(16)}`
    ).join(''));
  } catch (error) {
    clear();
  }

  return strDecoded;
}

(() => {
  /* eslint-disable func-names */
  Storage.prototype.setObjectHash = function (key, myObject) {
    const newObject = {};
    Object.keys(myObject).forEach(value => {
      newObject[value] = b64e(JSON.stringify(myObject[value]));
    });
    this.setItem(key, b64e(JSON.stringify(newObject)));
  }

  /* eslint-disable func-names, react/no-this-in-sfc */
  Storage.prototype.getObjectHash = function (key1) {
    const myObject = this.getItem(key1);
    if (myObject) {
      return b64d(myObject) && JSON.parse(b64d(myObject), function (key2) {
        return (key2) ? JSON.parse(b64d(this[key2])) : this[key2];
      });
    }
    return null;
  }
})();

const get = key => window.localStorage.getObjectHash(key);
const set = (key, item) => {
  window.localStorage.setObjectHash(key, item);
};

const getToken = () => window.localStorage.getItem(TOKEN_KEY);
const setToken = accessToken => {
  window.localStorage.setItem(TOKEN_KEY, accessToken);
};

const getRole = () => JSON.parse(window.localStorage.getItem(ROLE_KEY));
const setRole = role => {
  window.localStorage.setItem(ROLE_KEY, role);
};

const getJweKey = () => {
  const aesCipher = aes256gcm(Buffer.from(SECRET_KEY, 'hex'));
  let jweKey = window.localStorage.getItem(JWE_KEY);
  jweKey = aesCipher.decrypt(
    Buffer.from(jweKey.slice(0, 32), 'hex'),
    '0000000000000000',
    Buffer.from(jweKey.slice(32, 64), 'hex'),
  );
  return jweKey;
}
const setJweKey = jweKey => {
  const aesCipher = aes256gcm(Buffer.from(SECRET_KEY, 'hex'));
  const encJweKey = aesCipher.encrypt(
    Buffer.from(jweKey, 'hex'),
    '0000000000000000'
  );
  window.localStorage.setItem(JWE_KEY, encJweKey);
}

const clear = () => {
  window.localStorage.removeItem(TOKEN_KEY);
  window.localStorage.removeItem(ROLE_KEY);
  window.localStorage.removeItem(JWE_KEY);
};

export default {
  get,
  set,
  clear,
  getToken,
  setToken,
  getRole,
  setRole,
  getJweKey,
  setJweKey,
};
