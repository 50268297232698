import localStorage from './AccessTokenStorage';
const crypto = require('crypto');

const jose = require('node-jose');
const NodeRSA = require('node-rsa');
const publicKey =
  '-----BEGIN RSA PUBLIC KEY-----\n' +
  'MIIBCgKCAQEAgczn38xQfPMxcd2GH3APGw3e4o9hg3CB4OKRZ/d8T/1LDShfuG4j\n' +
  'xqL1w/6GnG0poH1K0uyV6e6J6e6En4qMtrvyJDiFAqWQPjBIlpb6V8/j/DEWsmzz\n' +
  'VgMAOkdNHrxLphktqjQSD+l3TK1fWVAfUoDQSzqSsqLqFSGH2Yhdrgv5K0aHW9kr\n' +
  '5JrzEMTswEVQy6bE0f3ig6Syp0/2CX9ol/TbM0knp+dPqHoQPBNl+r1HGHDNJ3uk\n' +
  'vP7zFvGgQnPym27owNytlrKJtJK6nf+DQcArCoSt2CbJI48xw7E2yTmRCrGxRKko\n' +
  'MtiS6RN05eYxs9u+Eldhm6mOBcQI7iEOoQIDAQAB\n' +
  '-----END RSA PUBLIC KEY-----';

const key = new NodeRSA();

/**
 *
 * @param len
 * @returns {string} random hex string generated
 */
const randHex = len => {
  const maxlen = 8;
  // eslint-disable-next-line no-restricted-properties
  const min = Math.pow(16, Math.min(len, maxlen) - 1);
  // eslint-disable-next-line no-restricted-properties
  const max = Math.pow(16, Math.min(len, maxlen)) - 1;
  const n = Math.floor(Math.random() * (max - min + 1)) + min;
  let r = n.toString(16);
  while (r.length < len) {
    r += randHex(len - maxlen);
  }
  return r;
};

const hex = randHex(32);

export const getTransKey = () => {
  key.setOptions({ encryptionScheme: 'pkcs1' });
  key.importKey(publicKey, 'pkcs1-public');

  const encryptData = key.encrypt(hex, 'hex', 'hex');

  return {
    encrypted: encryptData.toString(),
    clair: hex,
  };
};

export const aes256gcm = keyClair => {
  const ALGO = 'aes-128-gcm';

  // decrypt decodes base64-encoded ciphertext into a utf8-encoded string
  const decrypt = (enc, iv, authTag) => {
    const decipher = crypto.createDecipheriv(
      ALGO,
      keyClair,
      Buffer.from(iv, 'hex'),
    );
    decipher.setAuthTag(authTag);
    let str = decipher.update(enc, 'hex', 'hex');
    str += decipher.final('hex');
    return str;
  };
  return {
    decrypt
  };
};

export const getBufferHex = data => Buffer.from(data, 'hex');

export const getJwkKeystore = (hashKey = localStorage.getJweKey()) =>
      jose.JWK.asKey({
        k: getBufferHex(hashKey).toString('base64'),
        kty: 'oct',
        key_ops: ['wrapKey', 'unwrapKey'],
        kid: 'gray_key',
      }).then(result => result.keystore);

export const jweDecryption = (data, keystore) =>
  jose.JWE.createDecrypt(keystore)
    .decrypt(data)
    .then(result => JSON.parse(result.payload.toString('utf8')));

export const jweEncryption = (data, keystore) =>
  jose.JWE.createEncrypt(keystore.get('act_key'))
    .update(Buffer.from(JSON.stringify(data)))
    .final()
    .then(
      result =>
        `${result.protected}.${result.recipients[0].encrypted_key}.${
          result.iv
          }.${result.ciphertext}.${result.tag}`,
    );
