import React, {Component} from 'react';
import './App.css';
import {createMuiTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles';
import colors from './Colors'
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import FamilyListPage from "./containers/FamilyListPage";
import BrandListPage from "./containers/BrandListPage";
import LoginPage from "./containers/LoginPage";
import ProductListPage from "./containers/ProductListPage";
import AccessTokenStorage from "./security/AccessTokenStorage"
import InventoryPage from "./containers/Inventory";
import InventoryTransferPage from "./containers/Inventory/Transfer";

const customFontSize = {
    thin: 100,
    light: 300,
    regular: 400,
    medium : 500,
    semibold: 600,
    bold: 700
}

const theme = createMuiTheme({
    palette: {
        text: {
            primary: colors.dark_indigo,
            secondary: colors.grey
        },
        primary: {main: colors.indigo,},
        secondary: {main: colors.beige,},
        error: {main: colors.red},
        success: {main: colors.green},
    },
    typography: {
        fontFamily: [
            'Montserrat', 'sans-serif',
        ].join(','),
        h1: {
            fontWeight: customFontSize.semibold,
            fontSize: '1em',
        },
        h2: {
            fontWeight: customFontSize.semibold,
            fontSize: '1em',
        },
        h3: {
            fontWeight: customFontSize.semibold,
            fontSize: '1em',
        },
        h4: {
            fontWeight: customFontSize.medium,
            fontSize: '1em',
        },
        h5: {
            fontWeight: customFontSize.regular,
            fontSize: '1em',
        },
        caption: {
            fontWeight: customFontSize.medium,
            fontSize: '1em',
        },
        body1: {
            fontWeight: customFontSize.regular,
            fontSize: '1em',
        },
        body2: {
            fontWeight: customFontSize.regular,
            fontSize: '0.95em',
        },
        subtitle1: {
            fontWeight: customFontSize.medium,
            fontSize: '1em',
        },
    },
});

class App extends Component{
  render(){
      const token = AccessTokenStorage.getToken();
      return (
        <ThemeProvider theme={theme}>
          <div className='root'>
              <Router>
                  <Switch>
                      <Route exact path="/">
                          {token ? <Redirect to="/home" /> : <LoginPage/>}
                      </Route>
                      <Route exact path='/login'>
                          {token ? <Redirect to="/home" /> : <LoginPage/>}
                      </Route>
                      <Route exact path='/brands' component={BrandListPage} />
                      <Route exact path='/products' component={ProductListPage} />
                      <Route exact path='/stocks' component={InventoryPage} />
                      <Route exact path='/transfer-stock' component={InventoryTransferPage} />
                      <Route component={FamilyListPage} />
                  </Switch>
              </Router>
          </div>
        </ThemeProvider>
    );
  }
}

export default App;
