import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import InventoryTransfer from "../../../components/Inventory/Transfer";
import DrawerMenu from "../../../components/DrawerMenu";
import CustomAppBar from "../../../components/AppBar";
import { jweDecryption, getJwkKeystore } from '../../../security/crypt';
import requestWithAuth from "../../../requests/request-with-auth";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        marginTop: 84,
        marginRight: 16,
        marginLeft: 16,
        width: '100%',
        ...theme.mixins.toolbar
    },
}));

export default function InventoryTransferPage() {
    const classes = useStyles();
    const theme = useTheme();
    const [selectedIndex, setSelectedIndex] = React.useState('menu_transfer_stocks');
    const [open, setOpen] = React.useState(false);
    const [products, setProducts] = React.useState([]);
    const [brands, setBrands] = React.useState([]);
    const [families, setFamilies] = React.useState([]);
    const [stocks, setStocks] = React.useState([]);
    const MSG_DECRYPT_ERROR = 'KEY NOT VALID SESSION TIME OUT';
    let history = useHistory();

    const parseProducts= async (response)=>{
        try {
            let key = await getJwkKeystore();
            if (response !== '') {
                response = await jweDecryption(response, key);
                setProducts(response.content);
            }
        } catch (e) {
            console.error(e);
        }
    }
    const parseStocks= async (response)=>{
        try {
            let key = await getJwkKeystore();
            if (response !== '') {
                response = await jweDecryption(response, key);
                setStocks(response);
            }
        } catch (e) {
            console.error(e);
            throw Error(MSG_DECRYPT_ERROR);
        }
    }

    React.useEffect(() => {
        try {
            requestWithAuth('/products?page=0&size=10000', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }}, history
            ).then(parseProducts);
            requestWithAuth('/repos', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            }, history).then(parseStocks);
        } catch (err) {
            console.error(err);
        }
    }, []);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenuItemSelected = (event, index) => {
        setSelectedIndex(index);
        handleDrawerClose()
    };

    return (
        <React.Fragment key='left'>
            <div className={classes.root}>
                <CssBaseline />
                <CustomAppBar handleDrawerOpen={handleDrawerOpen} title='Transfet de stock'/>
                <DrawerMenu open={open}
                            handleMenuItemSelected={handleMenuItemSelected}
                            selectedIndex={selectedIndex}
                            handleDrawerClose={handleDrawerClose}
                />
                <main
                    className={classes.content}
                >
                    <InventoryTransfer products={products} stocks={stocks}/>
                </main>
            </div>
        </React.Fragment>
    );
}
